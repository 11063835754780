import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import resumePDF from "../data/devonyuresume.pdf"
import { Document, Page } from "react-pdf"
import { SectionHeader } from "../utils/commonComponents"

const PDFDocumentWrapper = styled.div`
  padding-top: 40px;
  canvas {
    width: 100% !important;
    height: auto !important;
    box-shadow: 22px 26px 61px 0px rgba(0, 0, 0, 0.63);
  }
`

const Downloadlink = styled.a`
  text-decoration: none;
  font-size: 2em;
  cursor: pointer;
`

const ResumePage = () => (
  <Layout>
    <SEO title="resume" />
    <SectionHeader header="RESUME" />
    <Downloadlink href={resumePDF} download>
      Download Resume
    </Downloadlink>
    <PDFDocumentWrapper
      data-sal="slide-left"
      data-sal-delay="1300"
      data-sal-duration="1500"
      data-sal-easing="ease"
    >
      <Document file={resumePDF}>
        <Page pageNumber={1} width={1000} />
      </Document>
    </PDFDocumentWrapper>
  </Layout>
)

export default ResumePage
